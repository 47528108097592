<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Tatil o'zgartirish</v-card-title>
        <v-card-text>
          <v-dialog
            ref="dialog1"
            v-model="modal"
            :return-value.sync="from_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="from_date"
                label="Tatil boshlanish sanasi"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="from_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog1.save(from_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog2"
            v-model="modal2"
            :return-value.sync="to_date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="to_date"
                label="Tatil tugash sanasi"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="to_date" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal2 = false">Yopish</v-btn>
              <v-btn text color="primary" @click="$refs.dialog2.save(to_date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>
          <v-dialog
            ref="dialog3"
            v-model="modal3"
            :return-value.sync="for_period_from"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="for_period_from"
                label="for_period_from (Davrgan)"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="for_period_from" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal3 = false">Yopish</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog3.save(for_period_from)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-dialog
            ref="dialog4"
            v-model="modal4"
            :return-value.sync="for_period_to"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="for_period_to"
                label="for_period_to (Davrgan)"
                append-icon="event"
                readonly
                v-bind="attrs"
                dense
                outlined
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="for_period_to" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal4 = false">Yopish</v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog4.save(for_period_to)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-dialog>

          <v-text-field
            label="Izoh"
            v-model="getData.comments"
            dense
            outlined
          ></v-text-field>

          <v-autocomplete
            v-model="name"
            :items="allStaff"
            label="Hodim"
            item-text="full_name"
            item-value="id"
            return-object
            dense
            outlined
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" @click="dialog = false"> Bekor qilish </v-btn>

          <v-btn color="success" @click="submit"> Yuborish </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      from_date: '',
      to_date: '',
      employee: '',
      employedSelect: '',
      comments: '',
      for_period_from: '',
      for_period_to: '',
      name: ''
    }
  },

  created() {
    this.$store.dispatch('getAllStaff')
    this.$store.dispatch('staffVacations')
  },
  watch: {
    getData(val) {
      this.name = val.employee
      this.from_date = val.from_date
      this.to_date = val.to_date
      this.for_period_from = val.for_period_from
      this.for_period_to = val.for_period_to
    }
  },
  computed: {
    getData() {
      const data2 = {}
      const data3 = this.$store.getters.staffVacations.results.find(
        (obj) => obj.id == this.staffData.id
      )
      const returnedTarget = Object.assign(data2, data3)

      return returnedTarget
    },
    allStaff() {
      return this.$store.getters.allStaff
    }
  },
  methods: {
    submit() {
      const data = {
        from_date: this.from_date,
        to_date: this.to_date,
        comments: this.getData.comments,
        for_period_from: this.for_period_from,
        for_period_to: this.for_period_to,
        employee: this.name.id
      }
      this.$store.dispatch('vacationUpdate', { id: this.staffData.id, data })
      this.dialog = false
    }
  },
  props: {
    staffData: Object
  }
}
</script>

<style></style>
