<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('MENU.STAFF.VACATION') }}
              </h3>
            </div>
            <div class="card-button">
              <create />
            </div>
          </div>
          <div class="card-body">
            <v-data-table
              :headers="headers"
              :page.sync="page"
              no-data-text="Ma'lumotlar topilmadi"
              loading-text="Ma'lumotlar yuklanmoqda"
              :loading="isLoading"
              :items="getAllVacations"
              @click:row="rowClick"
              item-key="id"
              show-select
              :items-per-page="10"
              class="row-pointer"
              hide-default-footer
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getAllVacations
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <action :index="item"></action>
                </div>
              </template>
            </v-data-table>

            <div class="text-center">
              <v-pagination
                v-model="currentPage"
                :length="Math.ceil(getPag / 10)"
                @input="getPostData(currentPage)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
import create from './create'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action, create },
  data() {
    return {
      perPage: 10,
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Sanadan',
          value: 'from_date'
        },
        {
          text: 'Sanagacha',
          value: 'to_date'
        },
        {
          text: 'Boshlangan davrdan',
          value: 'for_period_from'
        },
        {
          text: 'Boshlangan davrgacha',
          value: 'for_period_to'
        },
        {
          text: 'Izoh',
          value: 'comments'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    getAllVacations() {
      return this.$store.getters.staffVacations.results
    },
    getPag() {
      return this.$store.state.requests.staffVacations.count
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      this.$store.dispatch('staffVacations', value)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STAFF.TITLE') },
      { title: this.$t('MENU.STAFF.VACATION') }
    ])
  }
}
</script>

<style></style>
